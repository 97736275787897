/* eslint-disable react/jsx-key */

// CI__V1
import CI__V1__CoverWebGL from "../../components/slides/CI__V1/CoverWebGL";
import CI__V1__HistoricalDevelopmentCI from "../../components/slides/CI__V1/HistoricalDevelopmentCI";
import CI__V1__LeftLongTextRightImage from "../../components/slides/CI__V1/LeftLongTextRightImage";
import CI__V1__TopicOverViewCI from "../../components/slides/CI__V1/TopicOverViewCI";
import CI__V1__TopTopicsCI from "../../components/slides/CI__V1/TopTopicsCI";
import CI__V1__WordCloudAndKeywordsCI from "../../components/slides/CI__V1/WordCloudAndKeywordsCI";
import CI__V1__TopSearchesCI from "../../components/slides/CI__V1/TopSearchesCI";
import CI__V1__OneColumnsBlocks from "../../components/slides/CI__V1/OneColumnsBlocks";

// CI__V1__LIGHT
import CI__V1__LIGHT__CoverWebGL from "../../components/slides/CI__V1__LIGHT/CoverWebGL";
import CI__V1__LIGHT__TableOfContents from "../../components/slides/CI__V1__LIGHT/TableOfContents";
import CI__V1__LIGHT__TopSearchesCI from "../../components/slides/CI__V1__LIGHT/TopSearchesCI";

// NP__V1
import NP__V1__CoverWebGL from "../../components/slides/NP__V1/CoverWebGL";
import NP__V1__TableOfContents from "../../components/slides/NP__V1/TableOfContents";
import NP__V1__Brands from "../../components/slides/NP__V1/Brands";
import NP__V1__EtailerDistribution from "../../components/slides/NP__V1/EtailerDistribution";
import NP__V1__CategoryDistribution from "../../components/slides/NP__V1/CategoryDistribution";
import NP__V1__TopAttrEffConcIngr from "../../components/slides/NP__V1/TopAttrEffConcIngr";
import NP__V1__TopSizes from "../../components/slides/NP__V1/TopSizes";
import NP__V1__PricingAnalysis from "../../components/slides/NP__V1/PricingAnalysis";
import NP__V1__ProductList from "../../components/slides/NP__V1/ProductList";
import NP__V1__DataRoom from "../../components/slides/NP__V1/DataRoom";
import NP__V1__DataScope from "../../components/slides/NP__V1/DataScope";

// NP__V2
import NP__V2__CoverWebGL from "../../components/slides/NP__V2/CoverWebGL";
import NP__V2__TableOfContents from "../../components/slides/NP__V2/TableOfContents";
import NP__V2__Brands from "../../components/slides/NP__V2/Brands";
import NP__V2__EtailerDistribution from "../../components/slides/NP__V2/EtailerDistribution";
import NP__V2__CategoryDistribution from "../../components/slides/NP__V2/CategoryDistribution";
import NP__V2__TopAttrEffConcIngr from "../../components/slides/NP__V2/TopAttrEffConcIngr";
import NP__V2__TopSizes from "../../components/slides/NP__V2/TopSizes";
import NP__V2__PricingAnalysis from "../../components/slides/NP__V2/PricingAnalysis";
import NP__V2__ProductList from "../../components/slides/NP__V2/ProductList";
import NP__V2__DataRoom from "../../components/slides/NP__V2/DataRoom";
import NP__V2__DataScope from "../../components/slides/NP__V2/DataScope";

// NP__V1__LIGHT
import NP__V1__LIGHT__CoverWebGL from "../../components/slides/NP__V1__LIGHT/CoverWebGL";
import NP__V1__LIGHT__TableOfContents from "../../components/slides/NP__V1__LIGHT/TableOfContents";
import NP__V1__LIGHT__ProductList from "../../components/slides/NP__V1__LIGHT/ProductList";
import NP__V1__LIGHT__DataScope from "../../components/slides/NP__V1__LIGHT/DataScope";

// PS__V1
import PS__V1__CoverWebGL from "../../components/slides/PS__V1/CoverWebGL";
import PS__V1__TableOfContents from "../../components/slides/PS__V1/TableOfContents";
import PS__V1__ProductList from "../../components/slides/PS__V1/ProductList";
import PS__V1__DataRoom from "../../components/slides/PS__V1/DataRoom";
import PS__V1__ProductTypeDistribution from "../../components/slides/PS__V1/ProductTypeDistribution";
import PS__V1__TopAttributesEffects from "../../components/slides/PS__V1/TopAttributesEffects";
import PS__V1__TopConcernsIngredients from "../../components/slides/PS__V1/TopConcernsIngredients";
import PS__V1__TopSizesPricing from "../../components/slides/PS__V1/TopSizesPricing";
import PS__V1__DataScope from "../../components/slides/PS__V1/DataScope";

// PP__V1
import PP__V1__CoverWebGL from "../../components/slides/PP__V1/CoverWebGL";
import PP__V1__TableOfContents from "../../components/slides/PP__V1/TableOfContents";
import PP__V1__ProductList from "../../components/slides/PP__V1/ProductList";
import PP__V1__DataRoom from "../../components/slides/PP__V1/DataRoom";
import PP__V1__ProductTypeDistribution from "../../components/slides/PP__V1/ProductTypeDistribution";
import PP__V1__TopAttributes from "../../components/slides/PP__V1/TopAttributes";
import PP__V1__TopConcerns from "../../components/slides/PP__V1/TopConcerns";
import PP__V1__TopEffects from "../../components/slides/PP__V1/TopEffects";
import PP__V1__TopIngredients from "../../components/slides/PP__V1/TopIngredients";
import PP__V1__TopSizes from "../../components/slides/PP__V1/TopSizes";
import PP__V1__PricingAnalysis from "../../components/slides/PP__V1/PricingAnalysis";

// PP__V2
import PP__V2__CoverWebGL from "../../components/slides/PP__V2/CoverWebGL";
import PP__V2__TableOfContents from "../../components/slides/PP__V2/TableOfContents";
import PP__V2__ProductList from "../../components/slides/PP__V2/ProductList";
import PP__V2__DataRoom from "../../components/slides/PP__V2/DataRoom";
import PP__V2__ProductTypeDistribution from "../../components/slides/PP__V2/ProductTypeDistribution";
import PP__V2__TopAttributes from "../../components/slides/PP__V2/TopAttributes";
import PP__V2__TopConcerns from "../../components/slides/PP__V2/TopConcerns";
import PP__V2__TopEffects from "../../components/slides/PP__V2/TopEffects";
import PP__V2__TopIngredients from "../../components/slides/PP__V2/TopIngredients";
import PP__V2__TopSizes from "../../components/slides/PP__V2/TopSizes";
import PP__V2__TopBrands from "../../components/slides/PP__V2/TopBrands";
import PP__V2__PricingAnalysis from "../../components/slides/PP__V2/PricingAnalysis";

// PP__V1__LIGHT
import PP__V1__LIGHT__CoverWebGL from "../../components/slides/PP__V1__LIGHT/CoverWebGL";
import PP__V1__LIGHT__TableOfContents from "../../components/slides/PP__V1__LIGHT/TableOfContents";
import PP__V1__LIGHT__DataScope from "../../components/slides/PP__V1__LIGHT/DataScope";
import PP__V1__LIGHT__TopProducts from "../../components/slides/PP__V1__LIGHT/TopProducts";
import PP__V1__LIGHT__ProductList from "../../components/slides/PP__V1__LIGHT/ProductList";

// AZ__V1
import AZ__V1__CoverWebGL from "../../components/slides/AZ__V1/CoverWebGL";
import AZ__V1__DataScope from "../../components/slides/AZ__V1/DataScope";
import AZ__V1__TopProducts from "../../components/slides/AZ__V1/TopProducts";
import AZ__V1__ProductList from "../../components/slides/AZ__V1/ProductList";

// AZ__V2
import AZ__V2__CoverWebGL from "../../components/slides/AZ__V2/CoverWebGL";
import AZ__V2__DataScope from "../../components/slides/AZ__V2/DataScope";
import AZ__V2__Overview from "../../components/slides/AZ__V2/Overview";
import AZ__V2__TopProducts from "../../components/slides/AZ__V2/TopProducts";
import AZ__V2__ProductList from "../../components/slides/AZ__V2/ProductList";

// HSE__V1
import HSE__V1__CoverWebGL from "../../components/slides/HSE__V1/CoverWebGL";
import HSE__V1__DataScope from "../../components/slides/HSE__V1/DataScope";
import HSE__V1__Overview from "../../components/slides/HSE__V1/Overview";
import HSE__V1__TopProducts from "../../components/slides/HSE__V1/TopProducts";
import HSE__V1__ProductList from "../../components/slides/HSE__V1/ProductList";
import HSE__V1__TopBrands from "../../components/slides/HSE__V1/TopBrands";

// QVC__V1
import QVC__V1__CoverWebGL from "../../components/slides/QVC__V1/CoverWebGL";
import QVC__V1__DataScope from "../../components/slides/QVC__V1/DataScope";
import QVC__V1__Overview from "../../components/slides/QVC__V1/Overview";
import QVC__V1__TopProducts from "../../components/slides/QVC__V1/TopProducts";
import QVC__V1__ProductList from "../../components/slides/QVC__V1/ProductList";
import QVC__V1__TopBrands from "../../components/slides/QVC__V1/TopBrands";

// IG__V1
import IG__V1__CoverWebGL from "../../components/slides/IG__V1/CoverWebGL";
import IG__V1__TableOfContents from "../../components/slides/IG__V1/TableOfContents";
import IG__V1__DataScope from "../../components/slides/IG__V1/DataScope";
import IG__V1__Overview from "../../components/slides/IG__V1/Overview";
import IG__V1__TopPosts from "../../components/slides/IG__V1/TopPosts";
import IG__V1__Hashtags from "../../components/slides/IG__V1/Hashtags";
import IG__V1__BrandOverview from "../../components/slides/IG__V1/BrandOverview";

// BRAND__V1
import BRAND__V1__CoverWebGL from "../../components/slides/BRAND__V1/CoverWebGL";
import BRAND__V1__TableOfContents from "../../components/slides/BRAND__V1/TableOfContents";
import BRAND__V1__BrandPositioning from "../../components/slides/BRAND__V1/BrandPositioning";
import BRAND__V1__Ingredients from "../../components/slides/BRAND__V1/Ingredients";
import BRAND__V1__ScopeAndSize from "../../components/slides/BRAND__V1/ScopeAndSize";
import BRAND__V1__EcommerceOverview from "../../components/slides/BRAND__V1/EcommerceOverview";
import BRAND__V1__ConsumerInsights from "../../components/slides/BRAND__V1/ConsumerInsights";
import BRAND__V1__ReviewMentions from "../../components/slides/BRAND__V1/ReviewMentions";

// Generic
import ErrorSlide from "../../components/slides/_generic/ErrorSlide";

const componentMapping = {
    CI__V1: {
        CoverWebGL: CI__V1__CoverWebGL,
        HistoricalDevelopmentCI: CI__V1__HistoricalDevelopmentCI,
        LeftLongTextRightImage: CI__V1__LeftLongTextRightImage,
        TopicOverViewCI: CI__V1__TopicOverViewCI,
        TopTopicsCI: CI__V1__TopTopicsCI,
        WordCloudAndKeywordsCI: CI__V1__WordCloudAndKeywordsCI,
        TopSearchesCI: CI__V1__TopSearchesCI,
        OneColumnsBlocks: CI__V1__OneColumnsBlocks,
    },
    CI__V1__LIGHT: {
        CoverWebGL: CI__V1__LIGHT__CoverWebGL,
        TableOfContents: CI__V1__LIGHT__TableOfContents,
        TopSearchesCI: CI__V1__LIGHT__TopSearchesCI,
    },
    NP__V1: {
        CoverWebGL: NP__V1__CoverWebGL,
        TableOfContents: NP__V1__TableOfContents,
        Brands: NP__V1__Brands,
        EtailerDistribution: NP__V1__EtailerDistribution,
        CategoryDistribution: NP__V1__CategoryDistribution,
        TopAttrEffConcIngr: NP__V1__TopAttrEffConcIngr,
        TopSizes: NP__V1__TopSizes,
        PricingAnalysis: NP__V1__PricingAnalysis,
        ProductList: NP__V1__ProductList,
        DataRoom: NP__V1__DataRoom,
        DataScope: NP__V1__DataScope,
    },
    NP__V2: {
        CoverWebGL: NP__V2__CoverWebGL,
        TableOfContents: NP__V2__TableOfContents,
        Brands: NP__V2__Brands,
        EtailerDistribution: NP__V2__EtailerDistribution,
        CategoryDistribution: NP__V2__CategoryDistribution,
        TopAttrEffConcIngr: NP__V2__TopAttrEffConcIngr,
        TopSizes: NP__V2__TopSizes,
        PricingAnalysis: NP__V2__PricingAnalysis,
        ProductList: NP__V2__ProductList,
        DataRoom: NP__V2__DataRoom,
        DataScope: NP__V2__DataScope,
    },
    NP__V1__LIGHT: {
        CoverWebGL: NP__V1__LIGHT__CoverWebGL,
        TableOfContents: NP__V1__LIGHT__TableOfContents,
        ProductList: NP__V1__LIGHT__ProductList,
        DataScope: NP__V1__LIGHT__DataScope,
    },
    PS__V1: {
        CoverWebGL: PS__V1__CoverWebGL,
        TableOfContents: PS__V1__TableOfContents,
        ProductList: PS__V1__ProductList,
        DataRoom: PS__V1__DataRoom,
        ProductTypeDistribution: PS__V1__ProductTypeDistribution,
        TopAttributesEffects: PS__V1__TopAttributesEffects,
        TopConcernsIngredients: PS__V1__TopConcernsIngredients,
        TopSizesPricing: PS__V1__TopSizesPricing,
        DataScope: PS__V1__DataScope,
    },
    PP__V1: {
        CoverWebGL: PP__V1__CoverWebGL,
        TableOfContents: PP__V1__TableOfContents,
        ProductList: PP__V1__ProductList,
        DataRoom: PP__V1__DataRoom,
        ProductTypeDistribution: PP__V1__ProductTypeDistribution,
        TopAttributes: PP__V1__TopAttributes,
        TopConcerns: PP__V1__TopConcerns,
        TopEffects: PP__V1__TopEffects,
        TopIngredients: PP__V1__TopIngredients,
        TopSizes: PP__V1__TopSizes,
        PricingAnalysis: PP__V1__PricingAnalysis,
    },
    PP__V2: {
        CoverWebGL: PP__V2__CoverWebGL,
        TableOfContents: PP__V2__TableOfContents,
        ProductList: PP__V2__ProductList,
        DataRoom: PP__V2__DataRoom,
        ProductTypeDistribution: PP__V2__ProductTypeDistribution,
        TopAttributes: PP__V2__TopAttributes,
        TopBrands: PP__V2__TopBrands,
        TopConcerns: PP__V2__TopConcerns,
        TopEffects: PP__V2__TopEffects,
        TopIngredients: PP__V2__TopIngredients,
        TopSizes: PP__V2__TopSizes,
        PricingAnalysis: PP__V2__PricingAnalysis,
    },
    PP__V1__LIGHT: {
        CoverWebGL: PP__V1__LIGHT__CoverWebGL,
        TableOfContents: PP__V1__LIGHT__TableOfContents,
        DataScope: PP__V1__LIGHT__DataScope,
        TopProducts: PP__V1__LIGHT__TopProducts,
        ProductList: PP__V1__LIGHT__ProductList,
    },
    AZ__V1: {
        CoverWebGL: AZ__V1__CoverWebGL,
        DataScope: AZ__V1__DataScope,
        TopProducts: AZ__V1__TopProducts,
        ProductList: AZ__V1__ProductList,
    },
    AZ__V2: {
        CoverWebGL: AZ__V2__CoverWebGL,
        DataScope: AZ__V2__DataScope,
        Overview: AZ__V2__Overview,
        TopProducts: AZ__V2__TopProducts,
        ProductList: AZ__V2__ProductList,
    },
    HSE__V1: {
        CoverWebGL: HSE__V1__CoverWebGL,
        DataScope: HSE__V1__DataScope,
        Overview: HSE__V1__Overview,
        TopProducts: HSE__V1__TopProducts,
        ProductList: HSE__V1__ProductList,
        TopBrands: HSE__V1__TopBrands,
    },
    QVC__V1: {
        CoverWebGL: QVC__V1__CoverWebGL,
        DataScope: QVC__V1__DataScope,
        Overview: QVC__V1__Overview,
        TopProducts: QVC__V1__TopProducts,
        ProductList: QVC__V1__ProductList,
        TopBrands: QVC__V1__TopBrands,
    },
    IG__V1: {
        CoverWebGL: IG__V1__CoverWebGL,
        DataScope: IG__V1__DataScope,
        TableOfContents: IG__V1__TableOfContents,
        Overview: IG__V1__Overview,
        TopPosts: IG__V1__TopPosts,
        Hashtags: IG__V1__Hashtags,
        BrandOverview: IG__V1__BrandOverview,
    },
    BRAND__V1: {
        CoverWebGL: BRAND__V1__CoverWebGL,
        TableOfContents: BRAND__V1__TableOfContents,
        BrandPositioning: BRAND__V1__BrandPositioning,
        Ingredients: BRAND__V1__Ingredients,
        ScopeAndSize: BRAND__V1__ScopeAndSize,
        EcommerceOverview: BRAND__V1__EcommerceOverview,
        ConsumerInsights: BRAND__V1__ConsumerInsights,
        ReviewMentions: BRAND__V1__ReviewMentions,
    },
};

export const getSlidesMapping = ({ reportData, showFull = false }) => {
    const reportType = reportData.reportType.replace("__MOCK", ""); // Remove __MOCK from the reportType. This is needed when creating reports during development

    const slides = reportData.reportSlides.map((slide, index) => {
        let Component = componentMapping[reportType][slide.component];

        let slideCounter = {
            count: reportData.reportSlides.length,
            index: index + 1,
        };

        // If the component is not found, return an error slide
        // TODO: Add error logging
        if (!Component) return <ErrorSlide />;

        return (
            <Component
                slideContent={slide.slideData.slideContent}
                slideName={slide.slideData.slideName}
                reportName={reportData?.reportName}
                // reportVersion={reportData?.reportVersion}
                reportCreatedAt={reportData?.reportCreatedAt}
                reportLang={reportData?.reportLang}
                slideCounter={slideCounter}
                showFull={showFull}
            />
        );
    });

    return slides.filter(Boolean); // This will remove any null values from the slides array
};
