import { getHorizontalBarOptions } from "../_generic/chartOptions";
import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";
import ReactEcharts from "echarts-for-react";
import { TwoColumnLayout } from "../_generic/TwoColumnLayout";
import { PricingDistributionBar } from "../_generic/PricingDistributionBar";
import { mapCurrency } from "../../../utils/formatCurrency";
import { Rating } from "../_generic/Rating";

const ScopeAndSize = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        brand = "THE ORDINARY",
        noOfProducts = 341,
        avgRating = 4.5,
        noOfReviews = 1281,
        categories = [
            { attr: "Skincare", value: 341 },
            { attr: "Make Up", value: 181 },
            { attr: "Body", value: 81 },
            { attr: "Baby", value: 34 },
            { attr: "Other", value: 18 },
        ],
        distribution = [
            { attr: "Under 10€", value: 84 },
            { attr: "10€ - 25€", value: 81 },
            { attr: "25€ - 50€", value: 81 },
            { attr: "50€ - 100€", value: 34 },
            { attr: "100€ - 200€", value: 41 },
            { attr: "Above 200€", value: 18 },
        ],
    } = slideContent;

    return (
        <Layout showFull={showFull} className={"text-black"}>
            <div className="min-h-[690px] w-full flex flex-col">
                <div className="grid grid-cols-2">
                    <div
                        className="p-10 h-fit"
                        style={{ background: "#F0ECE6" }}
                    >
                        <div>
                            <h1 className=" text-4xl font-medium ">
                                {"Scope and Size"}
                            </h1>
                            <p className="mt-4 text-sm font-medium">
                                BRAND:{" "}
                                <span className="text-eyvablue">{brand}</span>{" "}
                            </p>
                        </div>
                    </div>
                    <div
                        className="h-full"
                        style={{ background: "white" }}
                    ></div>
                </div>

                <div className="grid grid-cols-2 grow">
                    <div
                        className="px-10 space-y-5"
                        style={{ background: "#F0ECE6" }}
                    >
                        <div className="bg-white px-6 py-4 rounded-md">
                            <p className="font-medium text-base">
                                <span className="text-[#DC715A]">
                                    {noOfProducts}
                                </span>{" "}
                                Product
                            </p>
                            <p className="text-xs text-gray-700">
                                Total Number of Products on E-Tailers
                            </p>
                        </div>
                        <div className="bg-white px-6 py-4 rounded-md">
                            <p className="font-medium text-base">
                                <span className="text-[#DC715A]">
                                    {avgRating}
                                </span>{" "}
                                <Rating rating={avgRating} color="#000000" />
                            </p>
                            <p className="text-xs text-gray-700">
                                Average Rating from {noOfReviews} reviews
                            </p>
                        </div>
                        <div className="bg-white px-6 py-4 rounded-md">
                            <p className="text-base font-medium">
                                Pricing Distribution
                            </p>
                            <div className="space-y-4 mt-4">
                                {distribution.map((item, id) => (
                                    <PricingDistributionBar
                                        key={id}
                                        label={mapCurrency(item.attr)}
                                        value={item.value}
                                        foregroud="#B9C69B"
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="px-10 -mt-5">
                        <div className="font-medium">TOP CATEGORIES</div>
                        <div className="mt-16">
                            <ReactEcharts
                                option={{
                                    series: [
                                        {
                                            data: categories.map((item) => ({
                                                name: item.attr,
                                                value: item.value,
                                            })),
                                            type: "pie",
                                            radius: "95%",

                                            center: ["50%", "50%"],
                                            label: {
                                                formatter: "{b}\n{per|{d}%}",
                                                padding: [10, 0],
                                                rich: {
                                                    per: {
                                                        color: "#9E9E9E",
                                                        lineHeight: 15,
                                                    },
                                                },
                                                position: "outer",
                                                alignTo: "edge",
                                                // edgeDistance: "25%",
                                                margin: 20,
                                            },
                                            colorBy: "data",
                                            // itemStyle: {},
                                        },
                                    ],
                                    color: [
                                        "#DC715A",
                                        "#A3BCBA",
                                        "#98ADBF",
                                        "#EDB258",
                                        "#DBB65A",
                                    ],
                                }}
                                opts={{ renderer: "svg" }}
                                style={{ height: "300px" }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default ScopeAndSize;
