import React from "react";
import Footer from "../_generic/Footer";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/solid";
import { Layout } from "../_generic/Layout";
import { classNames } from "../../../utils/classNames";
import { formatNumberCompact } from "../../../utils/formatNumber";
import { APP_URL } from "../../../utils/constants";
import { GrowthText } from "../_generic/GrowthText";

const colors = ["#E0EEE9", "#FEF9EE", "#F5F8FC"];

export const BRANDS_LOGO = {
    mueller_de: "/brand-logo/small-square/Mueller.png",
    dm_de: "/brand-logo/small-square/DM.png",
    douglas_de: "/brand-logo/small-square/Douglas.png",
};

export const DataRoom = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        h1 = "ERROR",
        h2 = "ERROR",
        products = [],
        category = "ERROR",
        subCategory = "ERROR",
        imageURL = "https://res.cloudinary.com/djgzit41g/image/upload/v1701935339/ICE/woman_light_eye_cxzqph.png",
    } = slideContent;

    return (
        <Layout showFull={showFull}>
            <div className="min-h-[690px] px-10 py-8 space-y-6">
                <h1 className="text-black text-17xl font-semibold leading-[54px]">
                    {h1}
                </h1>
                <div className="grid grid-cols-3 gap-4 text-black">
                    {products.map((product, index) => (
                        <div
                            key={index}
                            className="border relative p-6 rounded-md border-gray-300 space-y-5 text-center mx-auto truncate w-full flex flex-col justify-between"
                        >
                            <div className="space-y-5">
                                <div className="absolute top-5 left-5 rounded-full bg-yellow-200 w-12 h-12 text-base font-semibold flex justify-center items-center">
                                    #{index + 1}
                                </div>
                                <img
                                    src={product.imgURL}
                                    className="w-44 h-44 object-contain mx-auto"
                                />
                                <div className="font-medium space-y-2">
                                    <p className="text-sm text-gray-600">
                                        {product.brand}
                                    </p>
                                    <p className="whitespace-break-spaces truncate">
                                        {product.title}
                                    </p>
                                </div>
                            </div>
                            <div className="space-y-5">
                                <div className="flex justify-center space-x-2 w-full">
                                    {product?.eyva_rank_per_domain?.map(
                                        (rank, idx) => {
                                            return (
                                                <div
                                                    key={idx}
                                                    className="w-[130px] rounded-md p-3"
                                                    style={{
                                                        backgroundColor:
                                                            colors[
                                                                idx %
                                                                    colors.length
                                                            ],
                                                    }}
                                                >
                                                    <div className="h-11 w-11 rounded-full bg-white mx-auto">
                                                        <img
                                                            src={
                                                                BRANDS_LOGO[
                                                                    rank
                                                                        .domain_tld
                                                                ]
                                                            }
                                                            alt="domain_tld"
                                                            className=" object-contain "
                                                        />
                                                    </div>

                                                    <div className="mt-3 flex w-full justify-center rounded text-sm space-x-1">
                                                        <p> {rank.eyva_rank}</p>
                                                        {rank.eyva_rank_change ? (
                                                            <GrowthText
                                                                growth={
                                                                    rank.eyva_rank_change
                                                                }
                                                                arrows={true}
                                                            />
                                                        ) : (
                                                            <p className="text-[10px] text-gray-700">
                                                                -
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        },
                                    )}
                                </div>
                                <div>
                                    <a
                                        href={`${APP_URL}product-details/${product.p_c_id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-xs px-3.5 font-medium py-1 bg-gray-200 text-eyvablue rounded-full"
                                    >
                                        eyva link
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default DataRoom;
