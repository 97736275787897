import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/solid";
import { classNames } from "../../../utils/classNames";
import { formatNumberCompact } from "../../../utils/formatNumber";
export const GrowthText = ({ growth, arrows = false, className }) => {
    return (
        <div
            className={classNames(
                growth > 0
                    ? "text-green-600"
                    : growth < 0
                    ? "text-red-700"
                    : "",
                "flex items-center space-x-0.5 text-sm font-medium",
                className,
            )}
        >
            {arrows ? (
                growth > 0 ? (
                    <ArrowUpIcon className="h-2.5 w-2.5" />
                ) : growth < 0 ? (
                    <ArrowDownIcon className="h-2.5 w-2.5" />
                ) : null
            ) : (
                "+"
            )}
            {growth == null ? (
                <>&#x2015;</>
            ) : (
                <p>{formatNumberCompact(Math.abs(growth))}%</p>
            )}
        </div>
    );
};
