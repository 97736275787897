import React from "react";

export const TwoColumnLayout = ({
    title,
    rightSide,
    leftSide,
    color,
    leftColor,
}) => {
    return (
        <div className="min-h-[690px] w-full flex flex-col">
            {title && (
                <div className="grid grid-cols-2">
                    <div
                        className="p-10 h-fit"
                        style={{ background: leftColor ?? "white" }}
                    >
                        {title}
                    </div>
                    <div
                        className="h-full"
                        style={{ background: color ?? "white" }}
                    ></div>
                </div>
            )}
            <div className="flex grow">
                <div
                    className="w-1/2 p-10 h-full "
                    style={{ background: leftColor ?? "white" }}
                >
                    {leftSide}
                </div>
                <div className="grid w-1/2">
                    <div
                        className="p-10 h-full"
                        style={{ background: color ?? "white" }}
                    >
                        {rightSide}
                    </div>
                </div>
            </div>
        </div>
    );
};
