import { getHorizontalBarOptions } from "../_generic/chartOptions";
import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";
import ReactEcharts from "echarts-for-react";

const BrandPositioning = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        brand = "THE ORDINARY",
        brandInfo = "The Ordinary is a skincare and beauty brand that was founded in 2016 by DECIEM, a company that specializes in advanced functional beauty. The brand is focused on offering clinical formulations with integrity. The Ordinary is part of an umbrella of brands that includes Hylamide and...",
        attributes = [
            { attr: "vegan", value: 341 },
            { attr: "cruelty free", value: 181 },
            { attr: "vegan", value: 81 },
            { attr: "cruelty free", value: 34 },
            { attr: "vegan", value: 41 },
            { attr: "cruelty free", value: 18 },
        ],
    } = slideContent;

    return (
        <Layout showFull={showFull}>
            <div className="text-black w-full min-h-[690px] bg-[#F0ECE6] py-10 px-12">
                <h1 className=" text-4xl font-medium">{"Brand Positioning"}</h1>
                <p className="mt-4 text-sm font-medium">
                    BRAND: <span className="text-eyvablue">{brand}</span>{" "}
                </p>

                <div className="mt-5 text-sm leading-8 bg-white px-5 py-3 rounded-md text-black">
                    <div>{brandInfo}</div>
                </div>
                <div className="grid grid-cols-2 gap-5 mt-3">
                    <div className="">
                        <p className="text-sm font-medium">TOP ATTRIBUTES</p>
                        <div className="bg-white px-4 py-3 rounded-md h-full mt-3">
                            <ReactEcharts
                                option={getHorizontalBarOptions({
                                    data: attributes,
                                    color: "#DC715A",
                                })}
                                opts={{ renderer: "svg" }}
                                style={{ width: "560px", height: "340px" }}
                            />
                        </div>
                    </div>
                    <div className="">
                        <p className="text-sm font-medium">TOP CERTIFICATES</p>
                        <div className="bg-white px-4 py-3 rounded-md h-full mt-3"></div>
                        {/* <ReactEcharts
                            option={getHorizontalBarOptions({
                                data: attributes,
                                color: "#DC715A",
                            })}
                            opts={{ renderer: "svg" }}
                            style={{ width: "560px", height: "340px" }}
                        /> */}
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default BrandPositioning;
