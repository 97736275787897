import { getHorizontalBarOptions } from "../_generic/chartOptions";
import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";
import ReactEcharts from "echarts-for-react";
import { TwoColumnLayout } from "../_generic/TwoColumnLayout";
import { PricingDistributionBar } from "../_generic/PricingDistributionBar";
import { mapCurrency } from "../../../utils/formatCurrency";
import { Rating } from "../_generic/Rating";
import { formatNumberCompact } from "../../../utils/formatNumber";

const ReviewMentions = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        brand = "THE ORDINARY",
        avgRating = 4.5,
        noOfReviews = 1281,
        postiveMentions = [
            { attr: "Skincare", value: 341 },
            { attr: "Make Up", value: 181 },
            { attr: "Body", value: 81 },
            { attr: "Baby", value: 34 },
            { attr: "Other", value: 18 },
        ],
        negativeMentions = [
            { attr: "Skincare", value: 341 },
            { attr: "Make Up", value: 181 },
            { attr: "Body", value: 81 },
            { attr: "Baby", value: 34 },
            { attr: "Other", value: 18 },
        ],
    } = slideContent;

    return (
        <Layout showFull={showFull} className={"text-black"}>
            <div className="min-h-[690px] w-full flex flex-col">
                <div className="grid grid-cols-2">
                    <div
                        className="px-10 py-8 h-fit space-y-5"
                        style={{ background: "#F0ECE6" }}
                    >
                        <div>
                            <h1 className=" text-4xl font-medium ">
                                {"⭐ Review Mentions"}
                            </h1>
                            <p className="mt-4 text-sm font-medium">
                                BRAND:{" "}
                                <span className="text-eyvablue">{brand}</span>{" "}
                            </p>
                        </div>
                        <div className="">
                            <p className="font-medium text-base">
                                <span className="text-[#DC715A]">
                                    {avgRating}
                                </span>{" "}
                                <Rating rating={avgRating} color="#000000" />
                            </p>
                            <p className="text-xs text-gray-700">
                                Average Rating from{" "}
                                {formatNumberCompact(noOfReviews)} reviews
                            </p>
                        </div>
                    </div>
                    <div
                        className="h-full"
                        style={{ background: "#DDD4C680" }}
                    ></div>
                </div>

                <div className="grid grid-cols-2 grow">
                    <div
                        className="px-10 space-y-10"
                        style={{ background: "#F0ECE6" }}
                    >
                        <div className="">
                            <div>
                                <p className="text-sm font-medium">
                                    POSITIVE MENTIONS
                                </p>

                                <ReactEcharts
                                    option={getHorizontalBarOptions({
                                        data: postiveMentions,
                                        color: "#A3BCBA",
                                    })}
                                    opts={{ renderer: "svg" }}
                                    style={{ width: "560px", height: "240px" }}
                                />
                            </div>
                            <div>
                                <p className="text-sm font-medium">
                                    NEGATIVE MENTIONS
                                </p>

                                <ReactEcharts
                                    option={getHorizontalBarOptions({
                                        data: negativeMentions,
                                        color: "#CD0B52",
                                    })}
                                    opts={{ renderer: "svg" }}
                                    style={{ width: "560px", height: "240px" }}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        className="px-10 h-full"
                        style={{ background: "#DDD4C680" }}
                    >
                        <div className="font-medium">CONCLUSIONS</div>
                        <div className="mt-16 font-normal font-sm">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                        </div>
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default ReviewMentions;
