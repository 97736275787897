import { StarIcon } from "@heroicons/react/outline";
import { StarIcon as StarFilledIcon } from "@heroicons/react/solid";

export const Rating = ({ rating = 0, color = "#FAD163" }) => {
    const totalStars = 5;

    return (
        <div
            style={{
                display: "inline-flex",
                position: "relative",
                cursor: "pointer",
                textAlign: "left",
            }}
        >
            {[...new Array(totalStars)].map((arr, index) => {
                const activeState = rating;

                const showEmptyIcon =
                    activeState === -1 || activeState < index + 1;

                const isActiveRating = activeState !== 1;
                const isRatingWithPrecision = activeState % 1 !== 0;
                const isRatingEqualToIndex =
                    Math.ceil(activeState) === index + 1;
                const showRatingWithPrecision =
                    isActiveRating &&
                    isRatingWithPrecision &&
                    isRatingEqualToIndex;

                return (
                    <div className="relative" key={index}>
                        <div
                            style={{
                                width: showRatingWithPrecision
                                    ? `${(activeState % 1) * 100}%`
                                    : "0%",
                                overflow: "hidden",
                                position: "absolute",
                            }}
                        >
                            <StarFilledIcon
                                className={"h-4 w-4"}
                                color={color}
                            />
                        </div>
                        {/*Note here */}
                        <div
                            style={{
                                color: showEmptyIcon ? "gray" : "inherit",
                            }}
                        >
                            {showEmptyIcon ? (
                                <StarIcon className={"h-4 w-4"} color={color} />
                            ) : (
                                <StarFilledIcon
                                    className={"h-4 w-4"}
                                    color={color}
                                />
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
