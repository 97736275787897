import { getHorizontalBarOptions } from "../_generic/chartOptions";
import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";
import ReactEcharts from "echarts-for-react";
import { TwoColumnLayout } from "../_generic/TwoColumnLayout";
import { PricingDistributionBar } from "../_generic/PricingDistributionBar";
import { mapCurrency } from "../../../utils/formatCurrency";
import { Rating } from "../_generic/Rating";
import { GrowthText } from "../_generic/GrowthText";

const ConsumerInsights = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        brand = "THE ORDINARY",
        sv = 341,
        yoy = 10,
        chartData = [
            { date: "sept 2023", value: 341 },
            { date: "oct 2023", value: 181 },
            { date: "nov 2023", value: 81 },
            { date: "dec 2023", value: 34 },
            { date: "jan 2024", value: 18 },
        ],
        keywords = [
            { keyword: "the ordinary", sv: 84, yoy: 84 },
            { keyword: "the ordinary serum", sv: 81, yoy: 84 },
            { keyword: "the ordinary serum", sv: 81, yoy: 84 },
            { keyword: "the ordinary serum", sv: 34, yoy: 84 },
            { keyword: "the ordinary serum", sv: 41, yoy: 84 },
            { keyword: "the ordinary serum", sv: 18, yoy: 84 },
        ],
    } = slideContent;

    return (
        <Layout showFull={showFull} className={"text-black"}>
            <div className="min-h-[690px] w-full flex flex-col">
                <div className="grid grid-cols-2">
                    <div
                        className="p-10 h-fit"
                        style={{ background: "#F0ECE6" }}
                    >
                        <div>
                            <h1 className=" text-4xl font-medium ">
                                {"Consumer Interest"}
                            </h1>
                            <p className="mt-4 text-sm font-medium">
                                BRAND:{" "}
                                <span className="text-eyvablue">{brand}</span>{" "}
                            </p>
                        </div>
                    </div>
                    <div
                        className="h-full"
                        style={{ background: "white" }}
                    ></div>
                </div>

                <div className="grid grid-cols-2 grow">
                    <div
                        className="px-10 space-y-10"
                        style={{ background: "#F0ECE6" }}
                    >
                        <div className="flex items-center space-x-10">
                            <div>
                                <p className="font-semibold text-xl">{sv}</p>
                                <p className="text-xs text-gray-700">
                                    Monthly Google Search Volume
                                </p>
                            </div>
                            <div>
                                <GrowthText
                                    growth={yoy}
                                    className={"font-semibold text-xl"}
                                />
                                <p className="text-xs text-gray-700">
                                    YoY Growth
                                </p>
                            </div>
                        </div>
                        <div className="">
                            <p className="text-base font-medium">
                                GOOGLE SEARCH VOLUME OVER TIME
                            </p>
                            <div className="space-y-4 mt-4">
                                <ReactEcharts
                                    option={{
                                        xAxis: {
                                            data: chartData.map(
                                                (item) => item.date,
                                            ),
                                            splitLine: { show: true },
                                        },
                                        yAxis: {
                                            type: "value",
                                            splitLine: { show: true },
                                        },
                                        series: [
                                            {
                                                data: chartData.map(
                                                    (item) => item.value,
                                                ),
                                                type: "line",
                                            },
                                        ],
                                        color: [
                                            "#DC715A",
                                            "#A3BCBA",
                                            "#98ADBF",
                                            "#EDB258",
                                            "#DBB65A",
                                        ],
                                    }}
                                    opts={{ renderer: "svg" }}
                                    style={{ height: "300px" }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="px-10 -mt-5">
                        <div className="font-medium">
                            TOP GOOGLE KEYWORDS RELATED TO THE BRAND
                        </div>
                        <div className="mt-8">
                            <table className="w-full">
                                <thead className="text-[9px] text-gray-500 ">
                                    <th className="text-left py-3 font-normal">
                                        Combination
                                    </th>
                                    <th className="text-right font-normal">
                                        Search Volume
                                    </th>
                                    <th className="text-right font-normal">
                                        YoY Growth
                                    </th>
                                </thead>
                                {keywords.map((item, id) => (
                                    <tr
                                        key={id}
                                        className="border-b border-black"
                                    >
                                        <td className="py-3 font-medium">
                                            {item.keyword}
                                        </td>
                                        <td className="text-right">
                                            {item.sv}
                                        </td>
                                        <td className="flex justify-end py-3">
                                            <GrowthText
                                                growth={item.yoy}
                                                className={
                                                    "text-right font-medium"
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default ConsumerInsights;
